/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  BAck,
  NEW_CONVERSATION,
  notificationData,
  PUSH_NOTIFICATIONS,
  SEND_MESSAGE
} from './constant'
import NotificationCard from './NotificationCard'
import filePicker from '../../images/fileSelector.svg'
import smileyPicker from '../../images/smileyPicker.svg'
import linkAdder from '../../images/linkAddition.svg'
import EmojiPicker from 'emoji-picker-react'
import { adminServices } from '../../services'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../redux/store'
import { AntDesignFormSelect } from '../../sharedComponents/form-select/FormSelect'
import { LinkAdder } from './LinkAdder'

const PushNotificationComponent = () => {
  const [selectedNotification, setSelectedNotification] = useState(null)
  const [showNewConversation, setShowNewConversation] = useState(true)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [showLinkAdder, setShowLinkAdder] = useState(false)
  const [linkUrl, setLinkUrl] = useState('')
  const [linkText, setLinkText] = useState('')
  const [messageDetails, setMessageDetails] = useState({
    users: [],
    message_body: '',
    message_header: '',
    user_id_consent: 1
  })
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const existingUsers = useSelector(select.users.getUsersNotAdmin)

  useEffect(() => {
    // Check if existingUsers is empty or null
    if (!existingUsers || existingUsers.length === 0) {
      dispatch.users.getAllusers({
        showAll: true
      })
    }
  }, [existingUsers])

  useEffect(() => {
    if (existingUsers.length > 0) {
      setMessageDetails(prevDetails => ({
        ...prevDetails,
        users: existingUsers.map(user => user.id)
      }))
    }
  }, [existingUsers])
  const resetForm = () => {
    setMessageDetails({
      users: [],
      message_body: '',
      message_header: '',
      user_id_consent: 1
    })
  }

  const handleCardClick = notification => {
    setSelectedNotification(notification)
    setShowNewConversation(false) // Hide new conversation interface when clicking on a card
  }

  const handleNewConversationClick = () => {
    setShowNewConversation(true)
    setSelectedNotification(null) // Clear selected notification when showing new conversation interface
  }

  const handleBackButtonClick = () => {
    setSelectedNotification(null)
    setShowNewConversation(true) // Show new conversation interface when clicking back
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setMessageDetails({ ...messageDetails, [name]: value })
    if (name === 'message_body') {
      setCursorPosition(e.target.selectionStart)
    }
  }

  const handleUserChange = selectedUsers => {
    setMessageDetails(prevDetails => ({ ...prevDetails, users: selectedUsers }))
  }
  const handleLinkInsert = () => {
    const linkMarkdown = `[${linkText || linkUrl}](${linkUrl})`
    const { message_body } = messageDetails
    const before = message_body.substring(0, cursorPosition)
    const after = message_body.substring(cursorPosition)
    const newText = before + linkMarkdown + after
    setMessageDetails({
      ...messageDetails,
      message_body: newText
    })
    setShowLinkAdder(false)
    setLinkUrl('')
    setLinkText('')
  }

  const handleEmojiSelect = emojiObject => {
    const { message_body } = messageDetails
    const text = message_body
    const before = text.substring(0, cursorPosition)
    const after = text.substring(cursorPosition)
    const newText = before + emojiObject.emoji + after
    setMessageDetails({
      ...messageDetails,
      message_body: newText
    })
    setShowEmojiPicker(false)
  }

  const handleSendMessage = async () => {
    try {
      const { data } = await adminServices.adminSendMessage(
        {
          ...messageDetails,
          users: messageDetails.users
        },
        'PUSH_NOTIFICATION'
      )
      const { success, message } = data
      if (success) {
        notification.success({
          message: message || 'Message has been sent successfully',
          duration: 3
        })
        resetForm() // Reset the form after successfully sending the message
      } else {
        throw new Error(message || 'Failed to send message')
      }
    } catch (error) {
      console.error('Error sending message:', error)

      let errorMessage = 'An unexpected error occurred. Please try again.'

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || error.response.data.error || errorMessage
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'No response received from server. Please check your connection.'
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message
      }

      notification.error({
        message: 'Error Sending Message',
        description: errorMessage,
        duration: 5
      })
    }
  }

  return (
    <div className='flex w-full mb-8 px-3 flex-col justify-between'>
      <div className='flex items-center py-3 justify-between'>
        <h2 className='text-lg font-bold mb-4'>{PUSH_NOTIFICATIONS}</h2>
        <button
          className='bg-vittasBlue hover:bg-blue-600 text-white px-4 py-2 rounded'
          onClick={handleNewConversationClick}
        >
          {NEW_CONVERSATION}
        </button>
      </div>
      <div className='flex gap-4'>
        {/* Left Side: Messages */}
        <div className='w-1/2 bg-white p-4'>
          {/* Example message cards */}
          <div className='flex flex-col space-y-4'>
            {notificationData.map(item => (
              <div key={item.id} onClick={() => handleCardClick(item)} className='cursor-pointer'>
                <NotificationCard
                  name={item.name}
                  datetime={item.datetime}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Side: Detailed Notification or New Conversation */}
        <div className='w-full relative h-screen bg-white'>
          {selectedNotification && !showNewConversation ? (
            <div className='p-4'>
              <h3 className='text-lg font-bold mb-2'>{selectedNotification.name}</h3>
              <p className='text-sm text-gray-600 mb-2'>{selectedNotification.datetime}</p>
              <p>{selectedNotification.description}</p>
              <button
                className='bg-gray-200 text-gray-700 px-3 py-1 mt-4 rounded'
                onClick={handleBackButtonClick}
              >
                {BAck}
              </button>
            </div>
          ) : (
            <div className='p-4'>
              <AntDesignFormSelect
                mode='multiple'
                options={existingUsers.map(
                  ({ first_name: firstName, last_name: lastName, id }) => ({
                    label: `${firstName} ${lastName}`,
                    value: id
                  })
                )}
                value={messageDetails.users}
                onChange={handleUserChange}
                placeholder='Select Users'
                name='users'
              />
              <input
                type='text'
                placeholder='Subject'
                name='message_header'
                value={messageDetails.message_header}
                onChange={handleInputChange}
                className='w-full border-b py-3 border-gray-300 px-3 focus:outline-none focus:border-blue-500 mb-2'
              />
              <textarea
                placeholder='Type message here'
                name='message_body'
                value={messageDetails.message_body}
                onChange={handleInputChange}
                onSelect={e => setCursorPosition(e.target.selectionStart)}
                className='w-full focus:outline-none my-2'
              />
              <div className='flex absolute bottom-6 border-gray-200 border-t px-4 pt-4 w-full gap-4 items-center'>
                <button
                  className='bg-vittasBlue hover:bg-blue-600 text-white px-4 py-2 rounded'
                  onClick={handleSendMessage}
                >
                  {SEND_MESSAGE}
                </button>
                <div className='flex items-center space-x-4'>
                  <span>
                    <img src={filePicker} alt='File picker' className='cursor-pointer' />
                  </span>
                  <span onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <img src={smileyPicker} alt='Smiley picker' className='cursor-pointer' />
                  </span>
                  <span onClick={() => setShowLinkAdder(true)}>
                    <img src={linkAdder} alt='Link adder' className='cursor-pointer' />
                  </span>
                </div>
              </div>
              {showEmojiPicker && (
                <div className='absolute bottom-20'>
                  <EmojiPicker onEmojiClick={handleEmojiSelect} />
                </div>
              )}
              {showLinkAdder && (
                <LinkAdder
                  linkUrl={linkUrl}
                  setLinkUrl={setLinkUrl}
                  linkText={linkText}
                  setLinkText={setLinkText}
                  setShowLinkAdder={setShowLinkAdder}
                  handleLinkInsert={handleLinkInsert}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PushNotificationComponent
