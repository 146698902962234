/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */

import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import logo from '../../../images/logo.png'
import profile from '../../../images/profile.jpg'
import { RETURN_TO_WEBSITE, LOG_OUT, SIDE_MENU, ADMIN_SIDE_MENU } from './constants'
import { dispatch } from '../../../redux/store'
import { useSelector } from 'react-redux'
import { Badge } from 'antd'

const AdminNavBar = () => {
  const navRef = useRef()
  const [isOpen, setNavOpen] = useState(false)
  const [openUserMenu, setUserMenu] = useState(false)
  const history = useHistory()
  const { user } = useSelector(({ auth }) => ({ user: auth.authUser.userInfo }))
  const { pathname } = useLocation()
  const {
    userInfo: { is_admin }
  } = useSelector(({ auth }) => auth.authUser)
  const handleLogout = async () => {
    await dispatch.auth.logout()

    history.push('/login')
  }

  const getHeaderText = () => {
    let headerText = ''

    switch (pathname) {
      case '/admin-dashboard':
        headerText = `Welcome ${
          user?.data?.first_name?.charAt(0).toUpperCase() + user.data?.first_name?.slice(1)
        },`
        break
      case '/admin-approved':
        headerText = 'Approved Loans'
        break
      case '/admin-pending':
        headerText = 'Pending Applications'
        break
      case '/admin-disbursed':
        headerText = 'Disbursed Loans'
        break
      case '/admin-declined':
        headerText = 'Declined Applications'
        break
      case '/admin-customers':
        headerText = 'Customers'
        break
      case '/distributors':
        headerText = 'Distributors'
        break
      case '/admin-roles':
        headerText = 'Roles'
        break
      case '/transactions':
        headerText = 'Transactions'
        break
      case '/admin-upload-docs':
        headerText = 'Uplaod Docs'
        break
      case '/customer-directors':
        headerText = 'Customer Directors'
        break
      case '/administration':
        headerText = 'Administrator'
        break
      case '/templates':
        headerText = 'Templates'
        break
      case '/system-logs':
        headerText = 'System Logs'
        break
      case '/feedbacks':
        headerText = 'Feedbacks'
        break
      case '/messages':
        headerText = 'Message'
        break
      case '/interest-tracker':
        headerText = 'Interest Tracker'
        break
      case '/interest-payment-details':
        headerText = 'Interest Payment Details'
        break
      case '/notifications':
        headerText = 'Notifications'
        break
      case '/apply-express-loan':
        headerText = 'Application'
        break
      case '/admin-customers-module':
        headerText = 'Customers Module'
        break
      case '/admin-customers-details':
        headerText = 'Customers Module'
        break
      case '/admin-leads':
        headerText = 'Leads'
        break
      case '/finance':
        headerText = 'Finance'
        break
    }
    return headerText
  }

  useEffect(() => {
    if (isOpen) {
      navRef.current.style.maxHeight = '100%'
    } else {
      navRef.current.style.maxHeight = 0
    }
  }, [isOpen])

  return (
    <>
      <header className='w-full items-center bg-white py-2 px-6 hidden sm:flex'>
        <div className='w-1/2 text-black text-lg font-bold'>{getHeaderText()}</div>
        <div className='relative w-1/2 flex justify-end'>
          <div
            className={`${
              is_admin ? 'relative group block ml-2 mt-3 cursor-pointer font-semibold' : 'hidden'
            }`}
          >
            <Badge
              onClick={() => {
                history.push('/notifications')
              }}
              dot
              offset={[-43, 6]}
            >
              <i className='fa fa-bell text-xl text-gray-600 mr-10' />
            </Badge>
          </div>
          <button onClick={() => setUserMenu(prev => !prev)} className='nav-menu realtive'>
            <img src={profile} alt='Vittas' />
          </button>
          <span
            onClick={() => setUserMenu(prev => !prev)}
            className='hidden md:block ml-2 mt-4 cursor-pointer font-semibold'
          >
            {user?.data?.first_name?.charAt(0).toUpperCase() + user.data?.first_name?.slice(1)}{' '}
            {user?.data?.last_name?.charAt(0).toUpperCase() + user.data?.last_name?.slice(1)}{' '}
            <i className='fas fa-chevron-down ml-2' />
          </span>
          {openUserMenu && (
            <div
              onClick={() => setUserMenu(prev => !prev)}
              style={{ zIndex: '1' }}
              className='fixed inset-0'
            />
          )}
          <div
            className={`${
              !openUserMenu && 'hidden'
            } absolute z-20 top-0 bg-white rounded-lg shadow-lg py-2 mt-14`}
          >
            <Link to='/profile' className='nav-sm-link'>
              <i className='fas fa-user mr-2' /> Profile
            </Link>
            <Link to='/' className='nav-sm-link'>
              <i className='fas fa-columns mr-2' /> {RETURN_TO_WEBSITE}
            </Link>
            <button
              onClick={handleLogout}
              style={{ outline: 0 }}
              className='w-full text-left nav-sm-link text-red-600'
            >
              <i className='fas fa-sign-out-alt mr-2' />
              {LOG_OUT}
            </button>
          </div>
        </div>
      </header>

      <header className='w-full bg-sidebar py-5 px-6 sm:hidden'>
        <div className='flex items-center justify-between'>
          <Link
            to='/'
            className='text-black text-2xl md:text-3xl font-semibold hover:text-gray-300'
          >
            <img src={logo} alt='logo' className='inline-block' />
          </Link>
          <button
            onClick={() => setNavOpen(prev => !prev)}
            className='text-black text-3xl focus:outline-none'
          >
            <i className='fas fa-bars' />
            {/* <i x-show='isOpen' className='fas fa-times' /> */}
          </button>
        </div>
        {/* Dropdown Nav */}
        <nav ref={navRef} className='nav-mini mt-4'>
          {is_admin === 0 &&
            SIDE_MENU.map(item => (
              <Link
                to={item.url}
                key={item.id}
                className={`nav-link-md mt-4 ${pathname === item.url} `}
              >
                {item.icon ? (
                  <i className='fas fa-user text-xl mr-3 text-gray-400' />
                ) : (
                  <img
                    src={item.image}
                    alt='logo'
                    width='20'
                    height='20'
                    className='inline-block mr-2'
                  />
                )}
                {item.title}
              </Link>
            ))}
          {is_admin === 1 &&
            ADMIN_SIDE_MENU.map(item => (
              <Link
                to={item.route}
                key={item.id}
                className={`nav-link-md mt-4 ${pathname === item.route} `}
              >
                {item.icon ? (
                  <i className='fas fa-user text-xl mr-3 text-gray-400' />
                ) : (
                  <img
                    src={item.image}
                    alt='logo'
                    width='20'
                    height='20'
                    className='inline-block mr-2'
                  />
                )}
                {item.title}
              </Link>
            ))}
          <button onClick={handleLogout} className='nav-link-md text-red-800'>
            <i className='fas fa-sign-out-alt mr-3' />
            {LOG_OUT}
          </button>
        </nav>
      </header>
    </>
  )
}
export default AdminNavBar
