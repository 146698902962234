import React from 'react'
import PropTypes from 'prop-types'
import SmsIcon from '../../images/sms.svg'

const NotificationCard = ({ name, datetime, description }) => {
  return (
    <div className='bg-gray-100 rounded-lg p-4 mb-4'>
      {/* Top Section: Icon, Name, Date and Time */}
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center space-x-2'>
          <span>
            <img src={SmsIcon} alt='Icon' className='w-6 h-6' />
          </span>
          <p className='text-xs text-paragrapgh font-normal'>{name}</p>
        </div>
        <p className='text-10px text-paragrapgh font-normal'>{datetime}</p>
      </div>

      {/* Description */}
      <p className='text-headertxt2 text-xs'>{description}</p>
    </div>
  )
}

NotificationCard.propTypes = {
  name: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default NotificationCard
