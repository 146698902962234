import React from 'react'
import PropTypes from 'prop-types'

export const LinkAdder = ({
  linkUrl,
  setLinkUrl,
  linkText,
  setLinkText,
  setShowLinkAdder,
  handleLinkInsert
}) => {
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
      <div className='bg-white p-4 rounded-lg'>
        <h3 className='text-lg font-bold mb-2'>Add Link</h3>
        <input
          type='text'
          placeholder='Enter URL'
          value={linkUrl}
          onChange={e => setLinkUrl(e.target.value)}
          className='w-full border rounded px-2 py-1 mb-2'
        />
        <input
          type='text'
          placeholder='Enter link text (optional)'
          value={linkText}
          onChange={e => setLinkText(e.target.value)}
          className='w-full border rounded px-2 py-1 mb-2'
        />
        <div className='flex justify-end'>
          <button
            onClick={() => setShowLinkAdder(false)}
            className='bg-gray-300 text-black px-4 py-2 rounded mr-2'
          >
            Cancel
          </button>
          <button onClick={handleLinkInsert} className='bg-blue-500 text-white px-4 py-2 rounded'>
            Insert Link
          </button>
        </div>
      </div>
    </div>
  )
}

LinkAdder.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  setLinkUrl: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  setLinkText: PropTypes.func.isRequired,
  setShowLinkAdder: PropTypes.func.isRequired,
  handleLinkInsert: PropTypes.func.isRequired
}
