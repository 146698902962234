export const notificationData = [
  {
    id: 1,

    name: 'John Doe +20 others',
    datetime: '12/11/2023 9:00 AM',
    description:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit inceptos, arcu tempus libero conubia hendrerit placerat sodales dictum. '
  },
  {
    id: 2,

    name: 'Jane Smith',
    datetime: '12/11/2023 9:00 AM',
    description:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit inceptos, arcu tempus libero conubia hendrerit placerat sodales dictum. '
  },
  {
    id: 3,

    name: 'Alice Johnson',
    datetime: '12/11/2023 9:00 AM',
    description:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit inceptos, arcu tempus libero conubia hendrerit placerat sodales dictum. '
  }
]

const PUSH_NOTIFICATIONS = 'Push Notifications'
const NEW_CONVERSATION = 'New Conversation'
const BAck = 'Back'
const SEND_MESSAGE = 'Send Message'

export { PUSH_NOTIFICATIONS, NEW_CONVERSATION, BAck, SEND_MESSAGE }
